import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import ParameterMaintenance from "./ParameterMaintenance";
import ParameterEditPanel from "./ParameterEditPanel";
import { ParameterHeaderFormPanel } from "./Form/ParameterHeaderFormPanel";

export const ParameterMasterView: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const { isAdd, isEditable, isSliderOpen } = paramMainState.masterState;

    return <>
        <div className="main-comp-wrapper">            
            <ParameterMaintenance/>            
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"30%"}
                    rightSectionWidth={"70%"}
                    leftChildren={<ParameterHeaderFormPanel/>}
                    rightChildren={<ParameterEditPanel/>}
                />
                )
            }
        </div>
    </>
}