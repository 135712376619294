
export interface ParameterEntity {
    id:number,
    parameterType: string|null,
    parameterCode: string|null,
    parameterDesc: string|null,
    activeInd: string|null,
    operatingCompany: string|null,
    operatingUnit: string|null,

    [key:string]: string | number | null | undefined
}

export const EMPTY_PARAMETER_ENTITY:ParameterEntity = {
    id: 0,
    parameterType: null,
    parameterCode: null,
    parameterDesc: null,
    activeInd: "Y",
    operatingCompany: null,
    operatingUnit: null
}

