import { EMPTY_PARAMETER_MODEL } from "presentation/model/Parameter/ParameterMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: ParameterMaintenanceProvider,
    useTracked: useParameterMaintenanceTracked
} = createContainer(() => useState(EMPTY_PARAMETER_MODEL), {concurrentMode: true});
export { ParameterMaintenanceProvider, useParameterMaintenanceTracked };

