import { SelectionChangedEvent } from "ag-grid-community";
import { ParameterEntity } from "domain/entity/Parameter/ParameterEntity";
import _ from "lodash";
import { INITIAL_PARAMETER_COL_DEF, transferRowData } from "presentation/constant/Parameter/ParameterMaintenanceColumnDefinition";
import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef } from "react";


const ParameterTablePanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { tableData, selectedRows, } = paramMainState;
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();

    // useEffect(() => {
    //     const columnDefs = (INITIAL_TARIFF_CODE_COL_DEF.slice());

    //     if (!selectedRows || selectedRows.length <= 0) {
    //         //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //         if (!onTableSelectionClicked) {
    //             gridRef.current?.gridRef.current.api?.deselectAll();
    //         }
    //     }
    // })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        paramMainVM.updateSelectedRows(tableData, selectedRows);
    }, [paramMainVM, tableData])

    // useEffect(() => {
    //         if (onTableSelectionClicked) return;

    //         gridRef?.current?.gridRef.current.api?.deselectAll();
    //     }, [onTableSelectionClicked])

    const handleRowDoubleClick = useCallback((entity: ParameterEntity) => {
        paramMainVM.onRowDoubleClick(entity);
    }, [paramMainVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])

    const memoParameterTable = useMemo(() => {

        return (<div id="myDiv">
            {/*(allowUpdate) && <RightClickMenu disabled={_.isEmpty(selectedRows)} element={rightClickRef} items={moveItemList} />*/}
            <NbisTable
                id='parameter-table'
                isNewColumnSetting={true}
                headerLabel={ParameterMaintenanceConstant.TITLE}
                columns={INITIAL_PARAMETER_COL_DEF.slice()}
                data={transferRowData(tableData) ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: ParameterEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        </div>
        );
    }, [handleRowDoubleClick, handleSelectionChange, tableData])

    return <><TableWrapper>
        {memoParameterTable}
    </TableWrapper>
    </>;
}

export default memo(ParameterTablePanel);
