import { memo, useEffect } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import ParameterDetailTablePanel from "./Table/ParameterDetailTablePanel";
import { ParameterDetailPanel } from "./Form/ParameterDetailPanel";
import { ParameterSubDetailPanel } from "./Form/ParameterSubDetailPanel";

export const ParameterEditPanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const { detailState, subDetailState} = paramMainState;
    const parameterEty = paramMainState.currentSelectedRow;

    useEffect(() => {        
        parameterEty?.id && paramMainVM.onSearchParamDtl(parameterEty.id)
    }, [detailState.isSliderOpen, paramMainVM, parameterEty.id, subDetailState.isSliderOpen])
    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={detailState.isSliderOpen || subDetailState.isSliderOpen}
                    leftSectionWidth={'100%'}
                    rightSectionWidth={'60%'}
                    draggable={true}
                    leftChildren={<ParameterDetailTablePanel/>}
                    rightChildren={subDetailState.isSliderOpen?<ParameterSubDetailPanel/>:<ParameterDetailPanel/>} />
            </div>
    }
    
export default memo(ParameterEditPanel);