import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { ParameterRepoImpl } from "domain/repository/parameter/ParameterRepoImpl";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { ParameterMaintenanceVM } from "presentation/viewModel/Parameter/ParameterMaintenanceVM";
import { useMemo } from "react";

export const useParameterMaintenanceVM = () => {
    const [, setParameterMaintenanceState] = useParameterMaintenanceTracked();
    const parameterMainVM = useMemo(() =>
        ParameterMaintenanceVM({
            dispatch: [setParameterMaintenanceState],
            repo: ParameterRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setParameterMaintenanceState])

    return parameterMainVM
}

