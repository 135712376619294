
import { ParameterEntity, EMPTY_PARAMETER_ENTITY } from "domain/entity/Parameter/ParameterEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
import { EMPTY_PARAMETER_DETAIL_ENTITY, ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";

export interface ParameterDropdownOptions {
    parameterTypeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    updateParamEntity: ParameterEntity,
}

export interface ParameterDtlChangeState extends BaseViewChangeSate {
    isShowSubDtlTable: boolean,
    parameterDtlList: ParameterDetailEntity[],
    selectedRows: ParameterDetailEntity[],
    currentParamDtlEntity: ParameterDetailEntity,
}

export interface ParameterSubDtlChangeState extends BaseViewChangeSate {
    parameterSubDtlList: ParameterDetailEntity[],
    selectedRows: ParameterDetailEntity[],
    currentParamSubDtlEntity: ParameterDetailEntity,
}

export interface ParameterMaintenanceModel {
    isLoading: boolean,
    tableData: ParameterEntity[],
    currentSelectedRow: ParameterEntity,
    selectedRows: ParameterEntity[],
    dynamicOptions: ParameterDropdownOptions,
    masterState: MasterChangeState,
    detailState: ParameterDtlChangeState,
    subDetailState: ParameterSubDtlChangeState,
    isBackMaster: boolean,
}

export const EMPTY_PARAMETER_MODEL: ParameterMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_PARAMETER_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        parameterTypeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        updateParamEntity: EMPTY_PARAMETER_ENTITY,
    },
    detailState:{
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        isShowSubDtlTable: false,
        parameterDtlList: [],
        selectedRows: [],
        currentParamDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY        
    },
    subDetailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        parameterSubDtlList: [],
        selectedRows: [],
        currentParamSubDtlEntity: EMPTY_PARAMETER_DETAIL_ENTITY        
    },
    isBackMaster: false,
}