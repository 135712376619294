import { SelectionChangedEvent } from "ag-grid-community";
import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_PARAMETER_DETAIL_COL_DEF, transferRowData } from "presentation/constant/Parameter/ParameterDetailColumnDefinition";
import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ParameterSubDetailTablePanel from "./ParameterSubDetailTablePanel";

const ParameterDetailTablePanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();

    const { detailState, masterState, subDetailState } = paramMainState;
    const { parameterDtlList, isShowSubDtlTable } = detailState;

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [selectedRow, setSelectedRow] = useState<ParameterDetailEntity>();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_PARAMETER_DETAIL_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        paramMainVM.updateSelectedParamDtlRows(selectedRows);
        setSelectedRow(selectedRows[0]);
    }, [paramMainVM])

    const handleRowDoubleClick = useCallback((paramDtl: ParameterDetailEntity) => {
        paramMainVM.onParamDtlRowClick(paramDtl);
    }, [paramMainVM])

    const handleEditClick = useCallback(() => {
        if (selectedRow) {
            paramMainVM.onParamDtlEdit(selectedRow);
        } else {
            messageBarVM.showWarining(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        }
    }, [paramMainVM, selectedRow, messageBarVM])

    const handleAddClick = useCallback(async () => {
        paramMainVM.onParamDtlAdd();
    }, [paramMainVM])

    const memoParamSubDetailTablePanel = useMemo(() => {
        return <ParameterSubDetailTablePanel />
    }, [])

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        if (masterState.isEditable && !masterState.isRead && !detailState.isAdd && !detailState.isEditable && !subDetailState.isAdd && !subDetailState.isEditable) {
            tableBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })

            tableBtns.push({
                id: 'onAddButton',
                icon: 'Icon-add',
                title: 'Add'
            })
        }

        return tableBtns
    }, [masterState.isEditable, masterState.isRead, detailState.isAdd, detailState.isEditable, subDetailState.isAdd, subDetailState.isEditable])

    const memoParamDtlTable = useMemo(() => {
        return <NbisTable
            id='parameter-dtl-table'
            headerLabel={ParameterMaintenanceConstant.Detail.TITLE}
            columns={INITIAL_PARAMETER_DETAIL_COL_DEF.slice()}
            data={transferRowData(parameterDtlList) ?? []}
            headerActionButtons={memoTableBtns}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            isRowHighligted={true}
            selectionMode={false}
            rowSelection={"single"}
            onAddButton={handleAddClick}
            onSelectionChanged={(e: any) => handleSelectionChange(e)}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            onEditButton={(e: any) => handleEditClick()}
            gridHeight="customHeight"
            customHeight={isShowSubDtlTable ? "calc(100vh - 600px)" : "calc(100vh - 125px)"}
            ref={gridRef} />;
    }, [parameterDtlList, memoTableBtns, handleAddClick, isShowSubDtlTable, handleSelectionChange, handleRowDoubleClick, handleEditClick])

    return <>
        <div className="main-comp-wrapper">
            <div style={{ width: '100%', height: '100%', maxHeight: '95%', overflow: 'auto' }}>
                <TableWrapper>{memoParamDtlTable}</TableWrapper>
                {isShowSubDtlTable && <TableWrapper> {memoParamSubDetailTablePanel} </TableWrapper>}
            </div>
        </div>
    </>;
}

export default memo(ParameterDetailTablePanel);