import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ParameterMaintenanceProvider } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ParameterMasterView } from "presentation/view/section/Parameter/ParameterMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ParameterMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.PARAMETER_MAINTENANCE}>
        <MessageBarWrapper>
            <ParameterMaintenanceProvider>
                <GridStyles/>
                <ParameterMasterView/>
            </ParameterMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ParameterMaintenanceCont;