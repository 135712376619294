import * as yup from 'yup';


export const commonParameterMaintValidation: { [x: string]: any; } = {
  activeInd: yup.string().required("Missing")
};

export const commonParameterDetailValidation: { [x: string]: any; } = {
  parameterDtlCode: yup.string().required("Missing"),
  parameterDtlDesc: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing")
};

export const parameterMaintValidation: { [x: string]: any; } = {
  ...commonParameterMaintValidation,
};

export const parameterDetailValidation: { [x: string]: any; } = {
  ...commonParameterDetailValidation,
};

export const parameterMaintValidationSchema = yup.object().shape(parameterMaintValidation);
export const parameterDetailValidationSchema = yup.object().shape(parameterDetailValidation);