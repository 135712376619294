import { ParameterMaintenanceConstant } from "presentation/constant/Parameter/ParameterMaintenanceConstant";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { IFieldValue, NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo } from "react";
import { FieldType, HPHCheckbox } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ParameterEditPanelTitleBar from "../ParameterEditPanelTitleBar";

export const ParameterHeaderFormPanel = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();
    const { currentSelectedRow, masterState } = paramMainState;
    const { updateParamEntity, isAdd, isEditable, isRead, isSaveClicked } = masterState;
    const PARAM_MAIN_CONSTANT = ParameterMaintenanceConstant;

    const currentEntity = isRead ? currentSelectedRow : updateParamEntity;

    const handleTextAreaChange = useCallback((fieldName: string, fieldValue: IFieldValue) => {
        paramMainVM.onFieldChange(fieldName, fieldValue);
    }, [paramMainVM])

    const memoParamCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "480px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.parameterCode ?? ''}
                fieldValue={currentEntity?.parameterCode ?? ''}
                allValues={currentEntity?.parameterCode ?? ''}
                fieldLabel={PARAM_MAIN_CONSTANT.PARAMETER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'parameterCode'}
                isShowMissingError={false}
                maxLength={100}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    paramMainVM.onFieldChange(fieldKey, fieldValue, fFullValue)
                } />
        </div>
        , [PARAM_MAIN_CONSTANT.PARAMETER_CODE, currentEntity?.parameterCode, paramMainVM, isAdd, isSaveClicked])

    const memoParamDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "480px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parameterDesc ?? ''}
                fieldValue={currentEntity?.parameterDesc ?? ''}
                allValues={currentEntity?.parameterDesc ?? ''}
                fieldLabel={PARAM_MAIN_CONSTANT.PARAMETER_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'parameterDesc'}
                isShowMissingError={false}
                maxLength={100}
                requiredFieldList={[]}
                onFieldChange={handleTextAreaChange} />
        </div>
        , [PARAM_MAIN_CONSTANT.PARAMETER_DESC, currentEntity?.parameterDesc, handleTextAreaChange, isRead, isSaveClicked])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={PARAM_MAIN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => paramMainVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [PARAM_MAIN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, paramMainVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <ParameterEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {<SidebarTitle>{currentEntity?.parameterCode ?? ""}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <div style={{ marginTop: "24px" }}>
                        <CriteriaItemContainer>
                            {memoParamCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoParamDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
